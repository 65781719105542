import TodosPage from "pages/TodosPage";
import { lazy } from "react";
import { RouteObject } from "react-router";

const Home = lazy(() => import("pages/Home"));
const Login = lazy(() => import("pages/Login"));
const TripDetails = lazy(() => import("pages/TripDetails"));
const MatesPage = lazy(() => import("pages/MatesPage"));
const AddCostPage = lazy(() => import("pages/Costs/AddCost"));
const Costs = lazy(() => import("pages/Costs"));

const routes: RouteObject[] = [
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/trip",
    element: <TripDetails />,
  },
  {
    path: "/mates",
    element: <MatesPage />,
  },
  {
    path: "/todos",
    element: <TodosPage />,
  },
  {
    path: "/add-cost",
    element: <AddCostPage />,
  },
  {
    path: "/costs",
    element: <Costs />,
  },
];

export default routes;

import { useState } from "react";
import { Box, Grid, IconButton, Stack, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import { DragDropContext, Draggable } from "react-beautiful-dnd";
import { v4 as uuid } from "uuid";
import Checkbox from "@mui/material/Checkbox";
import { StrictModeDroppable } from "helpers/StrictModeDroppable";
import { Add20Filled, Delete24Regular } from "@fluentui/react-icons";
import { toast } from "react-toastify";
import TripLayout from "layout/TripLayout";

interface items {
  id: string;
  title: string;
  completed: boolean;
}
interface columns {
  id: number;
  items: items[];
}
const TodosPage = () => {
  const [value, setValue] = useState<string>("");

  const [columns, setColumns] = useState<columns[]>([
    {
      id: 1,
      items: [
        { id: uuid(), title: "بستن کیف", completed: false },
        { id: uuid(), title: "خرید مواد غذایی", completed: false },
      ],
    },
  ]);

  const onDragEnd = (result: any, columns: any, setColumns: any) => {
    if (!result.destination) return;
    const { source, destination } = result;

    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = columns[source.droppableId];
      const destColumn = columns[destination.droppableId];
      const sourceItems = [...sourceColumn.items];
      const destItems = [...destColumn.items];
      const [removed] = sourceItems.splice(source.index, 1);
      destItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...sourceColumn,
          items: sourceItems,
        },
        [destination.droppableId]: {
          ...destColumn,
          items: destItems,
        },
      });
    } else {
      const column = columns[source.droppableId];
      const copiedItems = [...column.items];
      const [removed] = copiedItems.splice(source.index, 1);
      copiedItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...column,
          items: copiedItems,
        },
      });
    }
  };

  const addItem = () => {
    if (value.length) {
      setColumns([
        {
          id: 1,
          items: [
            ...columns[0].items,
            { id: uuid(), title: value, completed: false },
          ],
        },
      ]);
      setValue("");
    } else toast.error("Please set a Title for new item");
  };

  const deleteItem = (id: any) => {
    const index = columns[0].items.findIndex((item) => item.id === id);
    const clone = [...columns[0].items];
    clone.splice(index, 1);
    setColumns([{ id: 1, items: [...clone] }]);
  };

  const handleChange = (id: string) => {
    const allItems = [...columns[0].items];
    const index = columns[0].items.findIndex((item) => item.id === id);
    const item = { ...allItems[index] };
    item.completed = !item.completed;
    allItems[index] = item;
    setColumns([{ id: 1, items: [...allItems] }]);
  };

  return (
    <TripLayout headerTitle="چمدان" padding="15px 18px 0px">
      <Box sx={{ padding: "15px 18px 0px" }}>
        <Box
          sx={{
            width: 1,
            marginTop: "10px !important",
            marginBottom: "30px !important",
          }}
        >
          <TextField
            id="outlined-basic"
            value={value}
            onChange={(e) => setValue(e.target.value)}
            onKeyDown={(e) => e.key === "Enter" && addItem()}
            placeholder="موارد مورد نیاز را یادداشت کنید"
            sx={{
              textAlign: "right",
              width: "100%",
              outline: "none",
              borderRadius: "10px",
              backgroundColor: "transparent",
              mt: "15px",
              input: {
                textAlign: "right",
                "&::placeholder": {
                  color: "red",
                  fontSize: "16px",
                  fontWeight: 400,
                },
              },
            }}
            InputProps={{
              sx: {
                input: {
                  paddingRight: "8px",
                  "&::placeholder": {
                    fontSize: "16px",
                    color: "#366B73 !important",
                    fontWeight: 400,
                  },
                },
              },
              startAdornment: (
                <InputAdornment position="start">
                  <Add20Filled primaryFill="#366B73" />
                </InputAdornment>
              ),
            }}
          />
          <DragDropContext
            onDragEnd={(result) => onDragEnd(result, columns, setColumns)}
          >
            {Object.entries(columns).map(([columnId, column], index) => {
              return (
                <Grid
                  container
                  direction="column"
                  sx={{ width: "100%", marginTop: "20px", gap: "10px" }}
                  key={columnId}
                >
                  <StrictModeDroppable droppableId={columnId}>
                    {(provided) => {
                      return (
                        <Stack
                          direction="column"
                          spacing={1.5}
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          {column.items.map((item: any, i: any) => {
                            return (
                              <Draggable
                                key={item.id}
                                draggableId={item.id}
                                index={i}
                              >
                                {(provided) => {
                                  return (
                                    <Grid
                                      container
                                      mx={0}
                                      item
                                      key={item.id}
                                      alignItems="center"
                                      flexDirection={"row"}
                                      sx={{
                                        width: "100%",
                                        padding: "10px",
                                        borderRadius: "14px",
                                        backgroundColor: "#EFF9FA",
                                        opacity: item.completed ? "0.8" : "1",
                                        gap: "2px",
                                        position: "relative",
                                        ...provided.draggableProps.style,
                                      }}
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                    >
                                      <Checkbox
                                        id={item.id}
                                        onChange={(e) =>
                                          handleChange(e.target.id)
                                        }
                                        checked={item.completed === true}
                                      />
                                      <Typography
                                        sx={{
                                          fontWeight: 500,
                                          color: "#5C5C5C",
                                          fontSize: "16px",
                                          textDecoration:
                                            item.completed && "line-through",
                                        }}
                                      >
                                        {item.title}
                                      </Typography>
                                      <IconButton
                                        aria-label="delete"
                                        color="secondary"
                                        sx={{
                                          position: "absolute",
                                          right: "10px",
                                        }}
                                        onClick={() => deleteItem(item.id)}
                                      >
                                        <Delete24Regular primaryFill="#A3BDBF" />
                                      </IconButton>
                                    </Grid>
                                  );
                                }}
                              </Draggable>
                            );
                          })}
                          {provided.placeholder}
                        </Stack>
                      );
                    }}
                  </StrictModeDroppable>
                </Grid>
              );
            })}
          </DragDropContext>
        </Box>
      </Box>
    </TripLayout>
  );
};

export default TodosPage;

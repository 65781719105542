import ReactDOM from "react-dom/client";
import "index.css";
import App from "App";
import { BrowserRouter } from "react-router-dom";
import { Suspense } from "react";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <Suspense fallback={<div>Loading... </div>}>
      <App />
    </Suspense>
  </BrowserRouter>
  // </React.StrictMode>
);

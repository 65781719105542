import { Box, Stack, Typography } from "@mui/material";
import { ArrowLeft16Regular } from "@fluentui/react-icons";
import { Link, useNavigate } from "react-router-dom";

interface CombinedCardsWrapperProps {
  title: string;
  actions?: JSX.Element;
  padding: string;
}
const CombinedCardsWrapper = ({
  title,
  actions,
  padding,
}: CombinedCardsWrapperProps) => {
  const navigate = useNavigate();

  return (
    <Box sx={{ padding }}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          boxSizing: "border-box",
          padding: "11px 5px 5px",
          marginBottom: "10px",
        }}
      >
        <Typography
          sx={{
            color: "#5F9299",
            fontSize: "15px",
            fontWeight: 700,
          }}
        >
          {title}
        </Typography>
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          sx={{ cursor: "pointer" }}
        >
          {actions ? (
            <Box onClick={() => navigate(-1)}>{actions}</Box>
          ) : (
            <Link
              to="/trip"
              style={{
                textDecoration: "none",
                display: "flex",
                alignItems: "center",
                gap: "10px",
                flexDirection: "row-reverse",
              }}
            >
              <ArrowLeft16Regular primaryFill="#5F9299" />
              <Typography
                sx={{
                  color: "#5F9299",
                  fontSize: "15px",
                  fontWeight: 500,
                }}
              >
                اطلاعات سفر
              </Typography>
            </Link>
          )}
        </Stack>
      </Stack>
    </Box>
  );
};

export default CombinedCardsWrapper;

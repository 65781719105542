import { FC, PropsWithChildren } from "react";
import { Box, Stack } from "@mui/material";
import CombinedCardsWrapper from "components/CardsWrapper/CombinedCardsWrapper";
import { ArrowLeft20Filled } from "@fluentui/react-icons";
type Props = {
  children: JSX.Element | JSX.Element[];
  headerTitle:string;
  padding:string;
};

const TripLayout: FC<PropsWithChildren<Props>> = ({ children,headerTitle,padding }) => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        paddingX: { lg: "35%", xs: "0px" },
        boxSizing: "border-box",
        position: "relative",
      }}
    >
      <Stack
        direction="column"
        sx={{
          width: "100%",
          height: "100%",
          minHeight: "100vh",
          backgroundColor: "#F7FCFC",
          boxSizing: "border-box",
          position: "relative",
        }}
      >

      <CombinedCardsWrapper
        title={headerTitle}
        actions={<ArrowLeft20Filled primaryFill="#5F9299" />}
        padding={padding}
        />
      {children}
        </Stack>
    </Box>
  );
};

export default TripLayout;
